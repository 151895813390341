
export default [
    {
        path: '/projects/cocacola',
        name: 'CocaCola',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Projects/CocaCola')
      },
      {
        path: '/projects/electrum',
        name: 'Electrum',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Projects/Electrum')
      },
      {
        path: '/projects/resourstrans',
        name: 'ResoursTrans',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Projects/ResoursTrans')
      },
      {
        path: '/projects/svgk',
        name: 'SVGK',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Projects/SVGK')
      },
      {
        path: '/projects/trasko',
        name: 'Trasko',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Projects/Trasko')
      },
      {
        path: '/projects/tampereentilapalvelut',
        name: 'TampereenTilapalvelut',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Projects/TampereenTilapalvelut')
      },
]