<template>
  <div class="hcont">
    {{ scrolling() }}
    <div class="header">
      <div class="header_logo">
        <img id="logo1" src="../assets/digital-images/smart-logo.png" alt="" width="80" height="46" @click="$router.push('/')" />
        <div>
          <div class="header_logo_title">Digital Platform</div>
          <div class="header_logo_text">Цифровая платформа</div>
        </div>
      </div>
      <div id="headertext" href="">
        {{ $t("message.HeaderText") }}
      </div>
      <div class="phone">
        <div class="phone-number">
          <v-icon color="white" id="mdi-head">mdi-phone</v-icon>
          <div>{{ $t("message.HeadPhone") }}</div>
        </div>
        <div id="phone-desc">
          <v-icon small color="white" id="mdi-head">mdi-email</v-icon>
          <div id="mail">{{ $t("message.HeadEmail") }}</div>
        </div>
      </div>
      <v-dialog v-model="dialog" width="500" id="fb-bg">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" elevation="2" outlined id="callback">{{ $t("message.RequestACall") }}</v-btn>
        </template>
        <v-card min-height="500">
          <div id="feedback-marg">
            <Feedback />
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Feedback from "../components/Feedback";
export default {
  components: {
    Feedback,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    scrolling() {
      window.addEventListener("scroll", callbackFunc);
      function callbackFunc() {
        var hcont = document.querySelector(".hcont");
        var y = window.pageYOffset;
        if (y > 50) {
          hcont.classList.add("scroll");
        } else {
          hcont.classList.remove("scroll");
        }
      }
    },
  },
};
</script>

<style>
/* Хедер */
.hcont {
  width: 100%;
  z-index: 101;
  position: fixed;
  background-color: #5c5c5c;
  /* background-color: #ffffff; */
}
.hcont img {
  transition: 1s;
}

.scroll {
  opacity: 0.8;
  background-color: #5c5c5c;
  transition: background-color 1s;
}
.scroll img {
  width: 58px;
  height: 33px;

  transition: 1s;
}
.scroll .header {
  border-bottom: none;
}
.scroll #headertext {
  opacity: 0;
  transition: 1s;
}
.scroll .phone {
  opacity: 0;
  transition: 1s;
}
.header {
  box-sizing: border-box;
  width: 70%;
  /* border: solid #5B6DCD 10px; */
  border-bottom: 1px solid #e3e7ec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  text-align: left;
  font: 16px/1.5 "Open Sans", sans-serif;
}

#headertext {
  margin: 10%;
  max-width: 300px;
  font: 13px "Ubuntu", Arial, sans-serif;
  line-height: 1.428571429;
  color: #ffffff;
}

.phone-number {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}
#phone-desc {
  color: #ffffff;
}
#mdi-head {
  margin-right: 5px;
}
#callback {
  height: 30px;
  width: 120px;
  margin-left: 30px;
  font-size: 10px;
  font-family: Ubuntu, Arial, sans-serif;
  background-color: #ffffff;
  color: #00569c;
  letter-spacing: 0.01em;
}
/* 
.phone-icon {	
  
} */

#phone-desc {
  display: flex;
  font-family: Ubuntu, Arial, sans-serif;
  font-size: 11px;
  text-align: right;
  /* color: #ffffff; */
}

.header_logo {
  display: flex;
}

.header_logo_title {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.header_logo_text {
  font-size: 12px;
  color: white;
}

@media (max-width: 1460px) {
  .header {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }
}
@media (max-width: 1140px) {
  #headertext {
    display: none;
  }
  .header {
    justify-content: space-around;
    height: 70px;
  }
  .scroll .phone {
    opacity: 1;
    transition: 1s;
  }
}

@media (max-width: 530px) {
  #headertext {
    display: none;
  }
  #callback {
    display: none;
  }
  .header {
    justify-content: space-around;
    height: 70px;
  }
  #logo1 {
    width: 110px;
    height: 40px;
  }
}
</style>
