<template>
  <div class="chapter">
    <div id="frequest">
      <div id="textcenter">
        <h1 id="naming">{{ $t("message.AnyQuestion") }}</h1>
      </div>
      <div id="freq-content">
        <div id="fform-left">
          <form>
            <v-text-field
              v-model="message.name"
              :counter="30"
              label="Name"
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="message.title"
              :items="items"
              label="Phone"
              required
              @change="$v.select.$touch()"
              @blur="$v.select.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="message.emailField"
              :error-messages="emailErrors"
              label="E-mail"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="message.message"
              
              label="Message"
              @input="$v.product.$touch()"
              @blur="$v.product.$touch()"
            ></v-text-field>
            <!-- <v-checkbox
              v-model="checkbox"
              :error-messages="checkboxErrors"
              label="Do you agree?"
              required
              @change="$v.checkbox.$touch()"
              @blur="$v.checkbox.$touch()"
            ></v-checkbox> -->

            <v-btn
              color="pink lighten-1"
              class="mr-4"
              width="200"
              v-on:click="SendEmail"
            >
              {{ $t("message.Submit") }}
            </v-btn>
            <v-btn color="" @click="clear">
              {{ $t("message.Clear") }}
            </v-btn>
          </form>
        </div>
        <!-- <div id="form-right"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
const axios = require("axios").default;

export default {
  mixins: [validationMixin],

  validations: {
    name: { required, maxLength: maxLength(30) },
    email: { email },
    select: {},
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  data: () => ({
    // name: "",
    // email: "",
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    config: "",
    message: {
      name: "",
      emailField: "",
      title: "",
      message: "",
    },
  }),

  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.name.required && errors.push("");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
    },
    clear() {
      this.$v.$reset();
      this.message.name = "";
      this.message.emailField = "";
      this.message.title = "";
      this.message.message = "";
      this.select = null;
      this.checkbox = false;
    },
    async SendEmail() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let request = axios.post(this.config + "/SendEmail", this.message);
      // let request = await axios.post('http://localhost:5542/api/Samupak/SendEmail',this.message)
      if (request.status && request.data) {
        this.message = {
          name: "",
          emailField: "",
          title: "",
          message: "",
        };
      } else {
        console.log("error sending feedback");
      }
    },
  },
};
</script>

<style>
#textcenter {
  text-align: center;
}
#frequest {
  background-color: rgb(255, 255, 255);
  min-height: 100px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20%;
  padding-right: 20%;
  align-items: center;
  font: 14px "Open Sans", sans-serif;
  padding-top: 20px;
}
#freq-content {
  display: flex;
  font: 13px/20px Ubuntu, Arial, sans-serif;
}
#bold {
  font-weight: 600;
}
#freq-text {
  margin-right: 20px;
}
#fform-left {
  width: 1000px;
}
/* #form-right {
  
} */
@media (max-width: 1140px) {
  
  #frequest {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 50px;
  }
  #freq-content {
    min-height: 100px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    align-items: flex-start;
    font: 14px "Open Sans", sans-serif;
    padding-top: 20px;
  }
  #freq-text {
    margin-right: 20px;
    width: 90%;
  }
  #fform-left {
    margin-left: 5%;
    width: 90%;
    padding-top: 20px;
  }
}
@media (max-width: 700px) {
  #frequest {
    padding-left: 1%;
    padding-right: 1%;
  }
  #freq-content {
    padding-left: 1%;
    padding-right: 1%;
  }
}
</style>