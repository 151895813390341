import Vue from 'vue'
import Vuex from 'vuex'
import loader from "./modules/loader"
import textblocks from "./modules/textblocks"
import pictureblocks from "./modules/pictureblocks"
import messageLoader from "./modules/messageLoader"
import newsblocks from "./modules/newsblocks"
import configGetter from "./modules/configGetter"
import articleblocks from "./modules/articleblocks"
import licenseblocks from "./modules/licenseblocks"
import partnerblocks from "./modules/partnerblocks"
import workblocks from "./modules/workblocks"


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    loader,
    textblocks,
    pictureblocks,
    messageLoader,
    newsblocks,
    configGetter,
    articleblocks,
    licenseblocks,
    partnerblocks,
    workblocks,
  }
})
